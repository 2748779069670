import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import '../assets/less/where.less'


export default ({ data }) => {
  const metaDataTitle = data.wordpressPage.acf.metadata_title ? data.wordpressPage.acf.metadata_title : "Axel Olson"
  const metaDataDescription = data.wordpressPage.acf.metadata_description ? data.wordpressPage.acf.metadata_description : "Architect."
  const metaDataImg = data.wordpressPage.acf.metadata_image ? data.wordpressPage.acf.metadata_image.localFile.url : ''
  return (
    <Layout page={data.wordpressPage.title}>
      <SEO title={metaDataTitle}
           description={metaDataDescription}
           imageURL={metaDataImg}
      />
      <div className="where-page content-section">
      <Img className="map-image" fluid={data.wordpressPage.acf.map_image.localFile.childImageSharp.fluid} />
        <div className="locations">
          { data.wordpressPage.acf.project_locations.map((projectLocation, i) =>
            <div className="location" key={i}>
              <h2 className="location-title">
                {projectLocation.project_location_name}
              </h2>
              {projectLocation.location.map((project) =>
                (project.project_url ? (
                    <a className="project" href={project.project_url}>
                      {project.project_name}
                    </a>
                  ) : (<div className="project">
                  {project.project_name}
                </div>)))
                }
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  wordpressPage(title: {eq: "Where"}) {
    title
    content
    id
    acf {
      map_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      project_locations {
        project_location_name
        location {
          project_name
          project_url
        }
      }
      metadata_title
      metadata_description
      metadata_image {
        localFile {
          url
        }
      }
    }
  }
}
`
